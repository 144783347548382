@import "~bootstrap/dist/css/bootstrap.css";
@import "~@angular/material/prebuilt-themes/indigo-pink.css";
@import "~bootstrap-icons/font/bootstrap-icons.css";
@import '~handsontable/dist/handsontable.full.css';

body{
    background-color: #f4f4f4;
    /* background-color: rgb(245, 245, 245); */
    /* background: url('assets/images/thumb-1920-1044851.jpg');
    background-size: cover;
    backdrop-filter: blur(10px) brightness(96%);
    width: 100%;
    height:100vh;
    margin: 0; */
    overflow: hidden;
}

@font-face {
    font-family: 'Poppins';
    src: url('assets/police/Poppins-Regular.ttf') format('truetype');
}

* {
    font-family: 'Poppins',Arial, Helvetica, sans-serif;
    font-size: 15px;
}


.primary{
    background-color: rgba(73, 110, 165, 0.349);
    transition: all ease-in-out .3s;
    &:hover{ background-color: rgba(73, 110, 165, 0.493);}
}

.success{
    background-color: rgba(80, 153, 89, 0.548);
    transition: all ease-in-out .3s;
    &:hover{ background-color: rgba(80, 153, 89, 0.676);}
}

.secondary{
    background-color: rgba(87, 87, 87, 0.25);
    transition: all ease-in-out .3s;
    &:hover{ background-color: rgba(87, 87, 87, 0.401);}
}


::-webkit-scrollbar {
    width: 10px;
    height: 10px;
}

::-webkit-scrollbar-track {
    background: #ffffff;
    border-radius: 2px;
}

::-webkit-scrollbar-thumb {
    background-color: rgba(193, 194, 199, 0.85);
    border-radius: 2px;
}

::-webkit-scrollbar-thumb:hover {
    background-color: rgb(193, 194, 199);
}

.btn-special{
    border: 1px solid #c4c4c4;
    border-radius: 5px;
    background-color: #fff;
    padding: 5px 10px 5px 10px;
    color: #4a5ddd;
    transition: ease .5s all;
    font-size: 12px;
    &:hover {
        background-color: #6676da;
        color: #fff;
        border: 1px solid #6676da;
    }
}

.btn-special-reverse{
    border-radius: 5px;
    padding: 5px 10px 5px 10px;
    transition: ease .5s all;
    font-size: 12px;
    background-color: #6676da;
    color: #fff;
    border: 1px solid #6676da;
    &:hover {
        border: 1px solid #c4c4c4;
        background-color: #fff;
        color: #4a5ddd;
    }
}

input:focus{
    box-shadow: none;
    border-color: #707fdd;
}

.text-special {
    color: #707fdd;
}